.ColorSchemeToggle_icon__hAqJq {
  width: calc(1.375rem * var(--mantine-scale));
  height: calc(1.375rem * var(--mantine-scale));
}

[data-mantine-color-scheme='dark'] .ColorSchemeToggle_dark__Hnbgo {
    display: none;
}

[data-mantine-color-scheme='light'] .ColorSchemeToggle_dark__Hnbgo {
    display: block;
}

[data-mantine-color-scheme='light'] .ColorSchemeToggle_light__B5SHF {
    display: none;
}

[data-mantine-color-scheme='dark'] .ColorSchemeToggle_light__B5SHF {
    display: block;
}

.Footer_footer__o4_Ec {
  margin-top: var(--mantine-spacing-xl);
  padding-top: calc(var(--mantine-spacing-xl));
  padding-bottom: calc(var(--mantine-spacing-xl));
  /* background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6)); */
  border-top: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-2);
}
  [data-mantine-color-scheme='dark'] .Footer_footer__o4_Ec {
  border-top: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-dark-5);
}

.Footer_logo__ZNv_V {
  max-width: calc(12.5rem * var(--mantine-scale));
}

@media (max-width: 48em) {

.Footer_logo__ZNv_V {
    display: flex;
    flex-direction: column;
    align-items: center
}
  }

.Footer_description___v0wZ {
  margin-top: calc(0.3125rem * var(--mantine-scale));
}

@media (max-width: 48em) {

.Footer_description___v0wZ {
    margin-top: var(--mantine-spacing-xs);
    text-align: center
}
  }

.Footer_inner__w0_wv {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 48em) {

.Footer_inner__w0_wv {
    flex-direction: column;
    align-items: start
}
  }

.Footer_groups__AWb5A {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 48em) {

.Footer_groups__AWb5A {
    flex-direction: column;
    margin-top: var(--mantine-spacing-md)
}
  }

.Footer_wrapper__znyEu {
  width: calc(10.625rem * var(--mantine-scale));
  padding-left: 2px;
  padding-right: 2px;
}

.Footer_link__wHCdx {
  display: block;
  color: var(--mantine-color-gray-6);
}

[data-mantine-color-scheme='dark'] .Footer_link__wHCdx {
  color: var(--mantine-color-dark-1);
}

.Footer_link__wHCdx {
  font-size: var(--mantine-font-size-sm);
  padding-top: calc(0.1875rem * var(--mantine-scale));
  padding-bottom: calc(0.1875rem * var(--mantine-scale));
  text-decoration: none;
}

.Footer_link__wHCdx:hover {
    text-decoration: underline;
  }

.Footer_title__aIcLm {
  font-size: var(--mantine-font-size-lg);
  font-weight: 700;
  font-family: Greycliff CF, var(--mantine-font-family);
  margin-bottom: calc(var(--mantine-spacing-xs) / 2);
  color: var(--mantine-color-black);
}

[data-mantine-color-scheme='dark'] .Footer_title__aIcLm {
  color: var(--mantine-color-white);
}

.Footer_afterFooter__9Bc_b {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mantine-spacing-xl);
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);
  border-top: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-2);
}

[data-mantine-color-scheme='dark'] .Footer_afterFooter__9Bc_b {
  border-top: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-dark-4);
}

@media (max-width: 48em) {

.Footer_afterFooter__9Bc_b {
    flex-direction: column
}
  }

@media (max-width: 48em) {

.Footer_social__ZBgR2 {
    margin-top: var(--mantine-spacing-xs)
}
  }

.HeaderMenu_header__fxmsp {
  height: calc(3.5rem * var(--mantine-scale));
  background-color: var(--mantine-color-body);
  position: relative;
}
.HeaderMenu_headerInternalContainer__iIwrF {
  border-bottom: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-3);
}
[data-mantine-color-scheme='dark'] .HeaderMenu_headerInternalContainer__iIwrF {
  border-bottom: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-dark-4);
}

.HeaderMenu_inner__1PxBo {
  height: calc(3.5rem * var(--mantine-scale));
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.HeaderMenu_link__BUFg5 {
  display: block;
  line-height: 1;
  padding: calc(0.5rem * var(--mantine-scale)) calc(0.75rem * var(--mantine-scale));
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: var(--mantine-color-dark-9);
}

[data-mantine-color-scheme='dark'] .HeaderMenu_link__BUFg5 {
  color: var(--mantine-color-dark-0);
}

.HeaderMenu_link__BUFg5 {
  font-size: var(--mantine-font-size-md);
  font-weight: 500;
}

@media (hover: hover) {
  .HeaderMenu_link__BUFg5:hover {
    background-color: var(--mantine-color-gray-0);
  }
    [data-mantine-color-scheme='dark'] .HeaderMenu_link__BUFg5:hover {
    background-color: var(--mantine-color-dark-6);
  }
}

@media (hover: none) {
  .HeaderMenu_link__BUFg5:active {
    background-color: var(--mantine-color-gray-0);
  }
    [data-mantine-color-scheme='dark'] .HeaderMenu_link__BUFg5:active {
    background-color: var(--mantine-color-dark-6);
  }
}

.HeaderMenu_subLink__xdoTV {
  display: block;
  line-height: 1;
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: var(--mantine-color-gray-7);
}

[data-mantine-color-scheme='dark'] .HeaderMenu_subLink__xdoTV {
  color: var(--mantine-color-dark-0);
}

.HeaderMenu_subLink__xdoTV {
  font-size: var(--mantine-font-size-md);
  font-weight: 500;
}

.HeaderMenu_linkLabel__FRT_g {
  margin-right: calc(0.3125rem * var(--mantine-scale));
}

.HeaderMenu_mobileMenu__LDNOi {
  position: fixed; /* Use fixed to make it stay in place even when scrolling */
  top: 56px; /* Adjust this value based on your header's height */
  left: 0;
  right: 0;
  background-color: #fff; /* Change this to match your navbar's background color */
  z-index: 10; /* Ensure it's above other content */
  display: flex;
  flex-direction: column;
  padding: 10px; /* Add some padding for spacing */
}

/* Improve the visibility of links in the mobile menu */
.HeaderMenu_mobileMenu__LDNOi a,
.HeaderMenu_mobileMenu__LDNOi button {
  margin: 5px 0; /* Add some vertical margin for spacing */
  text-align: center; /* Center align the text */
}

.m_17884d0f {
  --carousel-height: auto;
  position: relative;
}

.m_a2dae653 {
  height: var(--carousel-height);
  overflow: hidden;
}

.m_fcd81474 {
  display: flex;
  flex-direction: row;
  height: var(--carousel-height);
}

:where([data-include-gap-in-size]) .m_fcd81474:where([data-orientation='vertical']) {
      margin-bottom: calc(var(--carousel-slide-gap) * -1);
    }

:where([data-include-gap-in-size]) .m_fcd81474:where([data-orientation='horizontal']) {
      margin-inline-end: calc(var(--carousel-slide-gap) * -1);
    }

.m_fcd81474:where([data-orientation='vertical']) {
    flex-direction: column;
  }

.m_39bc3463 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  pointer-events: none;
}

.m_39bc3463:where([data-orientation='vertical']) {
    inset-inline-start: calc(50% - var(--carousel-control-size) / 2);
    top: 0;
    bottom: 0;
    flex-direction: column;
    padding: var(--carousel-controls-offset) 0;
  }

.m_39bc3463:where([data-orientation='horizontal']) {
    inset-inline-start: 0;
    inset-inline-end: 0;
    top: calc(50% - var(--carousel-control-size) / 2);
    flex-direction: row;
    padding: 0 var(--carousel-controls-offset);
  }

.m_64f58e10 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: var(--carousel-control-size);
  min-height: var(--carousel-control-size);
  border-radius: var(--carousel-control-size);
  pointer-events: all;
  background-color: var(--mantine-color-white);
  color: var(--mantine-color-black);
  box-shadow: var(--mantine-shadow-md);
  border: 1px solid var(--mantine-color-gray-3);
  transition: opacity 100ms ease;
}

:where([data-mantine-color-scheme='light']) .m_64f58e10 {
    opacity: 0.85;
}

:where([data-mantine-color-scheme='dark']) .m_64f58e10 {
    opacity: 0.65;
}

@media (hover: hover) {

  .m_64f58e10:hover {
    opacity: 1;
  }
}

@media (hover: none) {

  .m_64f58e10:active {
    opacity: 1;
  }
}

.m_71ea3ab1 {
  position: absolute;
  display: flex;
  justify-content: center;
  gap: calc(0.5rem * var(--mantine-scale));
  pointer-events: none;
}

.m_71ea3ab1:where([data-orientation='vertical']) {
    bottom: 0;
    top: 0;
    inset-inline-end: var(--mantine-spacing-md);
    flex-direction: column;
  }

.m_71ea3ab1:where([data-orientation='horizontal']) {
    bottom: var(--mantine-spacing-md);
    inset-inline-start: 0;
    inset-inline-end: 0;
    flex-direction: row;
  }

.m_eae68602 {
  pointer-events: all;
  border-radius: var(--mantine-radius-xl);
  box-shadow: var(--mantine-shadow-sm);
  opacity: 0.6;
  background-color: var(--mantine-color-white);
}

@media (hover: hover) {

  .m_eae68602:hover {
    opacity: 1;
  }
}

@media (hover: none) {

  .m_eae68602:active {
    opacity: 1;
  }
}

.m_eae68602:where([data-active]) {
    opacity: 1;
  }

.m_eae68602:where([data-orientation='vertical']) {
    width: calc(0.3125rem * var(--mantine-scale));
    height: calc(1.5625rem * var(--mantine-scale));
  }

.m_eae68602:where([data-orientation='horizontal']) {
    width: calc(1.5625rem * var(--mantine-scale));
    height: calc(0.3125rem * var(--mantine-scale));
  }

.m_d98df724 {
  position: relative;
  flex: 0 0 var(--carousel-slide-size);
}

.m_17884d0f:where([data-include-gap-in-size]) .m_d98df724:where([data-orientation='vertical']) {
      padding-bottom: var(--carousel-slide-gap);
    }

.m_17884d0f:where([data-include-gap-in-size]) .m_d98df724:where([data-orientation='horizontal']) {
      padding-inline-end: var(--carousel-slide-gap);
    }

.m_17884d0f:where(:not([data-include-gap-in-size])) .m_d98df724:where([data-orientation='vertical']) {
      margin-bottom: var(--carousel-slide-gap);
    }

.m_17884d0f:where(:not([data-include-gap-in-size])) .m_d98df724:where([data-orientation='horizontal']) {
      margin-inline-end: var(--carousel-slide-gap);
    }

.HeroSection_inner__JSk09 {
  display: flex;
  justify-content: space-between;
  padding-top: calc(var(--mantine-spacing-xl) * 4);
  padding-bottom: calc(var(--mantine-spacing-xl) * 4);
}

/* HeroSection.module.css */
.HeroSection_carouselImage__DX0vJ {
  position: relative;
  width: 100%;
  height: 575px;
  overflow: hidden;
}
.HeroSection_imageContainer__Rveb6 {
  position: relative;
  width: 100%;
  max-height: 550px; /* Fixed height for the BeforeAfterSlider */
  overflow: hidden;
}
/* Ensure images do not overflow */
.HeroSection_imageContainer__Rveb6 > img {
    margin: auto;
    object-fit: contain; /* Ensure images fit within the container */
    height: 100%;
    width: 100%;
  }

.HeroSection_beforeAfterContainer__RQVWs {
  background-color: var(--mantine-color-lime-1);
}

[data-mantine-color-scheme='dark'] .HeroSection_beforeAfterContainer__RQVWs {
  background-color: var(--mantine-color-dark-4);
}

.HeroSection_carouselContainer__AQM5B {
  background-color: var(--mantine-color-orange-1);
}

[data-mantine-color-scheme='dark'] .HeroSection_carouselContainer__AQM5B {
  background-color: var(--mantine-color-dark-4);
}

.HeroSection_carouselContainer__AQM5B {
  border-radius: 4rem;
}

.HeroSection_content__Z5zax {
  max-width: calc(30rem * var(--mantine-scale));
  margin-right: calc(var(--mantine-spacing-xl) * 3);
}

@media (max-width: 62em) {

.HeroSection_content__Z5zax {
    max-width: 100%;
    margin-right: 0
}
  }

.HeroSection_title__KhsSj {
  color: var(--mantine-color-black);
}

[data-mantine-color-scheme='dark'] .HeroSection_title__KhsSj {
  color: var(--mantine-color-white);
}

.HeroSection_title__KhsSj {
  font-size: calc(4rem * var(--mantine-scale));
  line-height: 1.2;
  font-weight: bolder;
}

@media (max-width: 36em) {

.HeroSection_title__KhsSj {
    font-size: calc(1.75rem * var(--mantine-scale))
}
  }

@media (max-width: 36em) {

.HeroSection_control__yr8WS {
    flex: 1
}
  }

.HeroSection_image__d6VUy {
  width: calc(23.5rem * var(--mantine-scale));
  height: calc(22.25rem * var(--mantine-scale));
}

@media (max-width: 62em) {

.HeroSection_image__d6VUy {
    display: none
}
  }

.HeroSection_highlight__41IkC {
  position: relative;
  background-color: var(--mantine-color-wumpus-light);
  border-radius: var(--mantine-radius-sm);
  padding: calc(0.25rem * var(--mantine-scale)) calc(0.75rem * var(--mantine-scale));
}

.HeroSection_splotch__2OIdN {
  position: fixed;
  border-radius: 50%;
  opacity: 0.7;
  z-index: -1;
}

.HeroSection_splotch1__rwa6A {
  top: 1%;
  right: 90%;
  width: 280px;
  height: 280px;
  background-color: var(--mantine-color-red-2);
}

.HeroSection_splotch2__pcfH_ {
  bottom: 55%;
  left: 95%;
  width: 500px;
  height: 500px;
  background-color: var(--mantine-color-blue-2);
}

.HeroSection_splotch3__DyOWa {
  top: 95%;
  left: 25%;
  width: 800px;
  height: 200px;
  background-color: var(--mantine-color-yellow-2);
}

