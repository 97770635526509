.inner {
  display: flex;
  justify-content: space-between;
  padding-top: calc(var(--mantine-spacing-xl) * 4);
  padding-bottom: calc(var(--mantine-spacing-xl) * 4);
}

/* HeroSection.module.css */
.carouselImage {
  position: relative;
  width: 100%;
  height: 575px;
  overflow: hidden;
}
.imageContainer {
  position: relative;
  width: 100%;
  max-height: 550px; /* Fixed height for the BeforeAfterSlider */
  overflow: hidden; /* Ensure images do not overflow */

  > img {
    margin: auto;
    object-fit: contain; /* Ensure images fit within the container */
    height: 100%;
    width: 100%;
  }
}

.beforeAfterContainer {
  background-color: light-dark(var(--mantine-color-lime-1), var(--mantine-color-dark-4));
}

.carouselContainer {
  background-color: light-dark(var(--mantine-color-orange-1), var(--mantine-color-dark-4));
  border-radius: 4rem;
}

.content {
  max-width: rem(480px);
  margin-right: calc(var(--mantine-spacing-xl) * 3);

  @media (max-width: $mantine-breakpoint-md) {
    max-width: 100%;
    margin-right: 0;
  }
}

.title {
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  font-size: rem(64px);
  line-height: 1.2;
  font-weight: bolder;

  @media (max-width: $mantine-breakpoint-xs) {
    font-size: rem(28px);
  }
}

.control {
  @media (max-width: $mantine-breakpoint-xs) {
    flex: 1;
  }
}

.image {
  width: rem(376px);
  height: rem(356px);

  @media (max-width: $mantine-breakpoint-md) {
    display: none;
  }
}

.highlight {
  position: relative;
  background-color: var(--mantine-color-wumpus-light);
  border-radius: var(--mantine-radius-sm);
  padding: rem(4px) rem(12px);
}

.splotch {
  position: fixed;
  border-radius: 50%;
  opacity: 0.7;
  z-index: -1;
}

.splotch1 {
  top: 1%;
  right: 90%;
  width: 280px;
  height: 280px;
  background-color: var(--mantine-color-red-2);
}

.splotch2 {
  bottom: 55%;
  left: 95%;
  width: 500px;
  height: 500px;
  background-color: var(--mantine-color-blue-2);
}

.splotch3 {
  top: 95%;
  left: 25%;
  width: 800px;
  height: 200px;
  background-color: var(--mantine-color-yellow-2);
}
