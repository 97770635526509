.header {
  height: rem(56px);
  background-color: var(--mantine-color-body);
  position: relative;
}
.headerInternalContainer {
  border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.inner {
  height: rem(56px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  display: block;
  line-height: 1;
  padding: rem(8px) rem(12px);
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: light-dark(var(--mantine-color-dark-9), var(--mantine-color-dark-0));
  font-size: var(--mantine-font-size-md);
  font-weight: 500;

  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
  }
}

.subLink {
  display: block;
  line-height: 1;
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
  font-size: var(--mantine-font-size-md);
  font-weight: 500;
}

.linkLabel {
  margin-right: rem(5px);
}

.mobileMenu {
  position: fixed; /* Use fixed to make it stay in place even when scrolling */
  top: 56px; /* Adjust this value based on your header's height */
  left: 0;
  right: 0;
  background-color: #fff; /* Change this to match your navbar's background color */
  z-index: 10; /* Ensure it's above other content */
  display: flex;
  flex-direction: column;
  padding: 10px; /* Add some padding for spacing */
}

/* Improve the visibility of links in the mobile menu */
.mobileMenu a,
.mobileMenu button {
  margin: 5px 0; /* Add some vertical margin for spacing */
  text-align: center; /* Center align the text */
}
